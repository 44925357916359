<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerP="p1" :bannerImg="Img1"></banner2>
    <div class="item2">
      <div class="item2_cont">
        <div class="item2_cont1" v-for="(item, index) in arrList" :key="index" @click="NewsDetails(item)">
          <img v-lazy="`${item.coverImg}`" alt="" lazy class="imgss" />
          <div class="item2_cont1_text">
            <div class="item2_cont1_text_top">
              <h1>
                {{ item.title }}
              </h1>
              <p>
                {{ item.secondTitle }}
              </p>
            </div>
            <div class="item2_cont1_text_btm">
              <div>{{item.createTime}}</div>
              <p>查看更多></p>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNum" :page-size="pageSize" layout=" prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>


<script>
import { newsList } from '../api/index'
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";

export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "企业新闻",
      p1: "更多最新精彩资讯，感受企业魅力",

      Img1: require("../assets/img/banner1.png"),
      arrList: [],
      total: 20,
      pageSize: 10,
      pageNum: null,
    };
  },
  mounted () {
    this.getNewsList()
  },
  methods: {
    NewsDetails (val) {
      this.$router.push({ path: `/newsxiangqing/${val.id}` });
    },
    getNewsList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      newsList(params).then((res) => {
        this.arrList = res.rows
        this.total = res.total
      })
    },
    handleCurrentChange () {
      this.getNewsList()
    }

  },
};
</script>
<style scoped>
/* 新闻列表 */
.item_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.item2_cont1:first-child {
  display: flex;
  width: 1200px;
  height: 252px;
  background: #ffffff;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  /* height: 218px; */
  height: 188px;
  /* border: dashed; */
}
.imgss {
  margin: 32px 48px 32px 32px;
  width: 300px;
  /* height: 218px; */
  height: 188px;
  /* border: dashed; */
}
.item_cont1_text {
  width: 868px;
}
.item_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 24px;
}
.item_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
.item_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
/* item2 */
.item2_cont {
  width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
}
.item2_cont1 {
  margin-top: 30px;
  display: flex;
  width: 1200px;
  height: 252px;
  cursor: pointer;
  /* background: #f8f8f8; */
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

.item2_cont1 img {
  margin: 32px 48px 32px 32px;
  width: 300px;
  height: 188px;
  /* border: dashed; */
}
.item2_cont1_text {
  width: 868px;
}
.item2_cont1_text_top {
  margin: 45px 0 16px 0;
  width: 732px;
  height: 112px;

  border-bottom: 1px solid #eaeaea;
}
.item2_cont1_text_top > h1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  color: #3f3f3f;
  line-height: 24px;
}
.item2_cont1_text_top p {
  margin-top: 16px;
  width: 732px;
  height: 56px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}
.item2_cont1_text_btm {
  width: 732px;

  display: flex;
  justify-content: space-between;
}
.item2_cont1_text_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  color: #999999;
  line-height: 28px;
}
.item2_cont1_text_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23337f;
  line-height: 28px;
}
</style>


